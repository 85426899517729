
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EmailInput from '@/components/inputs/EmailInput.vue';
import { BModal } from 'bootstrap-vue';
import { AddRemoveAccountUserSubmission } from '@/store/models.def';
import accountx from '@/store/modules/accountx';
import progressToastx from '@/store/modules/progressToastx';
import NewUserEmailInput from './NewUserEmailInput.vue';

@Component({
	components: {
		NewUserEmailInput,
	},
})
export default class AddUserModal extends Vue {
	public email: string = '';
	public validateEmailNow: boolean = false;

	public get currentAccount() {
		return accountx.currentAccount;
	}

	public async onOk(e: Event) {
		this.validateEmailNow = true;
		if (!this.email || !this.currentAccount) {
			e.preventDefault();
			return;
		}
		const submission: AddRemoveAccountUserSubmission = {
			email: this.email,
			accountId: this.currentAccount.accountId,
		};
		const toastId = await progressToastx.add({
			title: `${this.currentAccount.companyName}`,
			state: `Adding new user...`,
			variant: 'info',
		});
		this.hide();
		const result = await accountx.addRemoveAccountUser(submission);
		if (result.success) {
			progressToastx.done({ id: toastId, state: `New user added!` });
		} else {
			progressToastx.error({ id: toastId, state: result.errorMessage });
		}
		this.resetModal();
	}

	public resetModal() {
		this.email = '';
		this.validateEmailNow = false;
	}
	public hide() {
		const modal = this.$refs.inviteUserModal as BModal;
		if (modal) {
			modal.hide();
		}
	}
	public show() {
		const modal = this.$refs.inviteUserModal as BModal;
		if (modal) {
			modal.show();
		}
	}
}
