
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EmailInput from '../../inputs/EmailInput.vue';
import accountx from '@/store/modules/accountx';

@Component({
	components: {},
})
export default class NewUserEmailInput extends EmailInput {
	public get isUserExist(): boolean {
		return this.allUsers.includes(this.input);
	}

	public get invalidError() {
		return this.isUserExist ? `User exists` : `Invalid Email.`;
	}
	public get allUsers() {
		return Object.keys(accountx.currentAccountPermissions);
	}

	public validate(value: string) {
		if (value === null) {
			return false;
		}
		const result = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
		return result && !this.isUserExist;
	}
}
