
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
	AccountUserPermission,
	AddRemoveAccountUserSubmission,
} from '@/store/models.def';
import accountx from '@/store/modules/accountx';
import progressToastx from '@/store/modules/progressToastx';
import userx from '@/store/modules/userx';
import resize from 'vue-resize-directive';

@Component({
	directives: {
		resize,
	},
	components: {},
})
export default class UserPermissions extends Vue {
	@Prop({ default: {} }) public data!: AccountUserPermission;
	@Prop({ default: '' }) public email!: string;
	@Prop({ default: false }) public editable!: string;
	@Prop({ default: [] }) public removingUsers!: string[];

	public admin: boolean = false;
	public dashboard: boolean = false;
	public sales: boolean = false;
	public purchases: boolean = false;
	public quotations: boolean = false;
	public transactions: boolean = false;
	public suppliers: boolean = false;
	public customers: boolean = false;
	public payroll: boolean = false;
	public stocks: boolean = false;
	public forecastRead: boolean = false;
	public forecastWrite: boolean = false;

	public width: number = 0;

	public sent: boolean = false;

	public get small() {
		return this.width < 910;
	}

	public get currentAccount() {
		return accountx.currentAccount;
	}

	public get isBeingRemoved() {
		return this.removingUsers.includes(this.email);
	}

	public get isMe() {
		if (!userx.user) {
			return false;
		}
		return this.email === userx.user.email;
	}

	public get isSupport() {
		return userx.supportLvl && userx.supportLvl > 1 ? true : false;
	}

	public get invitationSent() {
		return userx.recentInvitedEmails.has(this.email);
	}

	public get canSend() {
		return !this.data.uid && !this.sent && !this.invitationSent;
	}

	public get status() {
		if (this.data.uid) {
			return 'Joined';
		}
		if (this.invitationSent) {
			return 'Invitation Sent';
		}
		return 'Sending...';
	}

	public async onRemove() {
		if (!this.email || !this.currentAccount) {
			return;
		}
		if (this.admin && accountx.numAdmins === 1) {
			progressToastx.error({
				title: 'Failed',
				state: 'Account must have at least ONE admin.',
			});
			return;
		}
		this.$emit('remove', this.email, this.data.displayName);
	}
	public loadFromData() {
		const {
			admin,
            dashboard,
			sales,
			purchases,
			quotations,
			transactions,
			suppliers,
			customers,
			payroll,
			stocks,
			forecastRead,
			forecastWrite,
		} = this.data;
		this.admin = admin || false;
		this.dashboard = dashboard || false;
		this.sales = sales || false;
		this.purchases = purchases || false;
		this.quotations = quotations || false;
		this.transactions = transactions || false;
		this.suppliers = suppliers || false;
		this.customers = customers || false;
		this.payroll = payroll || false;
		this.stocks = stocks || false;
		this.forecastRead = forecastRead || false;
		this.forecastWrite = forecastWrite || false;
	}
	public mounted() {
		this.loadFromData();
	}
	@Watch('data')
	public onDataChange(newVal: AccountUserPermission) {
		this.loadFromData();
	}
	@Watch('admin')
	public onAdminChange(newVal: boolean, oldVal: boolean) {
		if (newVal) {
			this.dashboard = true;
			this.sales = true;
			this.purchases = true;
			this.quotations = true;
			this.transactions = true;
			this.suppliers = true;
			this.customers = true;
			this.payroll = true;
			this.stocks = true;
			this.forecastRead = true;
			this.forecastWrite = true;
		}
	}
	public onCheck(target: string) {
		const {
			admin,
			dashboard,
			sales,
			purchases,
			quotations,
			transactions,
			suppliers,
			customers,
			payroll,
			stocks,
			data,
			email,
			forecastRead,
			forecastWrite,
		} = this;
		if (!this.isSupport && target === 'admin' && this.isMe && admin) {
			progressToastx.error({
				title: 'Failed',
				state: 'You can not remove yourself as admin.',
			});
			this.$nextTick(() => {
				this.admin = true;
			});
		} else {
			this.$nextTick(() => {
				this.update();
			});
		}
	}
	public update() {
		const {
			admin,
			dashboard,
			sales,
			purchases,
			quotations,
			transactions,
			suppliers,
			customers,
			payroll,
			stocks,
			data,
			email,
			forecastRead,
			forecastWrite,
		} = this;

		if (!this.currentAccount) {
			return;
		}
		if (
			admin !== data.admin ||
			dashboard !== data.dashboard ||
			sales !== data.sales ||
			purchases !== data.purchases ||
			quotations !== data.quotations ||
			transactions !== data.transactions ||
			suppliers !== data.suppliers ||
			customers !== data.customers ||
			payroll !== data.payroll ||
			stocks !== data.stocks ||
			forecastRead !== data.forecastRead ||
			forecastWrite !== data.forecastWrite
		) {
			const accountId = this.currentAccount.accountId;
			accountx.setPermissions({
				email,
				accountId,
				admin,
				dashboard,
				sales,
				purchases,
				quotations,
				transactions,
				suppliers,
				customers,
				payroll,
				stocks,
				forecastRead,
				forecastWrite,
			});
		}
	}
	public async onInvite() {
		this.sent = true;
		await userx.sendSignInEmail(this.email);
	}

	public onResize() {
		this.width = this.$el.clientWidth;
	}
}
