
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import accountx from '@/store/modules/accountx';
import { BModal } from 'bootstrap-vue';
import AddUserModal from './AddUserModal.vue';
import UserPermissions from './UserPermissions.vue';
import userx from '@/store/modules/userx';
import { AddRemoveAccountUserSubmission } from '@/store/models.def';
import progressToastx from '@/store/modules/progressToastx';

@Component({
	components: {
		AddUserModal,
		UserPermissions,
	},
})
export default class AllUsers extends Vue {
	public removingUsers: string[] = [];

	public get allUsers() {
		return Object.values(accountx.currentAccountPermissions).sort((a, b) =>
			(a.displayName || a.email).localeCompare(b.displayName || b.email),
		);
	}

	public get numUsers() {
		return Object.keys(this.allUsers).length;
	}

	public get isAdmin() {
		if (!userx.user) {
			return false;
		}
		const email = userx.user.email;
		return (
			accountx.currentAccountPermissions[email] &&
			accountx.currentAccountPermissions[email].admin
		);
	}
	public get isSupport() {
		if (!userx.supportLvl) {
			return false;
		}
		return userx.supportLvl >= 2;
	}
	public get editable() {
		return this.isSupport || this.isAdmin;
	}

	public onInviteNewMember() {
		const modal = this.$refs.inviteUserModal as BModal;
		if (modal) {
			modal.show();
		}
	}
	public async onRemoveMember(email: string, displayName: string) {
		const currentAccount = accountx.currentAccount;
		if (!currentAccount) {
			return;
		}
		const h = this.$createElement;
		const titleVNode = h('div', {
			domProps: {
				innerHTML: `<i class="fas fa-exclamation-triangle fa-fw text-warning"></i>  <b>Remove User</b>`,
			},
		});
		const msgVNode = h('div', {
			class: 'text-gray-700',
			domProps: {
				innerHTML: `Are you sure you want to remove <span class="text-cyan">${
					displayName || email
				}</span>?`,
			},
		});
		const toRemove = await this.$bvModal.msgBoxConfirm([msgVNode], {
			title: [titleVNode],
			size: 'sm',
			buttonSize: 'sm',
			okVariant: 'pink',
			cancelVariant: 'link-gray',
			okTitleHtml: `<i class="fa fa-times"></i> Remove`,
			headerClass: 'p-2 border-bottom-0',
			footerClass: 'p-2 border-top-0',
		});
		if (toRemove) {
			const submission: AddRemoveAccountUserSubmission = {
				email,
				accountId: currentAccount.accountId,
				remove: true,
			};

			const toastId = await progressToastx.add({
				title: `${currentAccount.companyName}`,
				state: `Removing user...`,
				variant: 'info',
			});
			this.removingUsers = [...this.removingUsers, email];
			const result = await accountx.addRemoveAccountUser(submission);
			if (result.success) {
				progressToastx.done({ id: toastId, state: `User removed!` });
			} else {
				progressToastx.error({ id: toastId, state: result.errorMessage });
			}
			this.removingUsers = this.removingUsers.filter((e) => e !== email);
		}
	}
	public mounted() {
		(window as any).onRemoveMember = this.onRemoveMember;
	}
}
